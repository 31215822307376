<template>
  <page-container
    is-show-breadcrumbs
    style="padding-bottom: 24px"
    class="custom-el-style"
  >
    <Specification>
      <template #singleline> 添加自定义标识分类用于在标识管理中手动添加标识时，作为新增标识的分类</template>
    </Specification>
    <el-button type="primary" @click="handleOpen"> 添加分类 </el-button>
    <el-table
      :data="listData"
      :header-cell-style="{
        height: '60px',
        fontSize: '14px',
        fontWeight: 400,
        color: '#909399',
        background: '#FAFAFA',
      }"
      row-key="cat_id"
      :tree-props="{ children: 'cat_child' }"
      style="margin-top: 16px; font-size: 14px"
    >
      <el-table-column
        style="padding-left: 16px"
        prop="cat_name"
        label="分类名称"
        min-width="300"
      />
      <el-table-column min-width="80" width="150px" align="left" label="操作">
        <template slot-scope="scope">
          <el-button
            style="padding: 10px 16px 10px 0"
            type="text"
            v-if="!scope.row.cat_type"
            @click="handleEdit(scope.row)"
          >
            编辑
          </el-button>
          <el-button
            style="padding: 10px 16px 10px 0"
            type="text"
            class="red_color"
            v-if="!scope.row.cat_type"
            @click="handleDelete(scope.row.cat_id)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :title="isEdit ? '编辑分类' : '添加分类'"
      width="25%"
      center
      :visible.sync="isShowDialog"
    >
      <el-form :model="form" ref="form">
        <el-form-item label="所属类别：" :label-width="formLabelWidth">
          <el-select
            class="el-tree-select-input"
            v-model="selectMenu"
            popper-class="select-option"
            ref="select"
            v-bind="selectParams"
            :popper-append-to-body="true"
            v-popover:popover
          />
          <el-popover
            ref="popover"
            popper-class="el-tree-select-popper"
            trigger="click"
            :width="340"
            v-model="isShowPopover"
          >
            <el-scrollbar
              tag="div"
              wrap-class="el-select-dropdown__wrap"
              view-class="el-select-dropdown__list"
            >
              <!-- 树列表 -->
              <el-tree
                ref="tree"
                v-bind="treeParams"
                :data="treeParams.menuList"
                node-key="cat_id"
                @node-click="selectMenuInfo"
              />
            </el-scrollbar>
          </el-popover>
        </el-form-item>
        <el-form-item label="分类名称：" :label-width="formLabelWidth">
          <el-input v-model="form.name" style="width: 50%" autocomplete="off" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="clearable">取 消</el-button>
        <el-button type="primary" @click="addCateory">确 定</el-button>
      </div>
    </el-dialog>
  </page-container>
</template>
<script>
import PageContainer from "@/components/common/PageContainer.vue";
import Specification from "@/components/common/Specification.vue";
import { identifierApi } from "@/services/api";
export default {
  components: {
    PageContainer,
    Specification
  },
  data() {
    return {
      selectMenu: "",
      isEdit: false,
      cat_parentid: "",
      isShowPopover: false,
      treeParams: {
        clickParent: true,
        filterable: false,
        menuList: [],
        props: {
          children: "cat_child",
          label: "cat_name",
          value: "cat_id"
        }
      },
      selectParams: {
        multiple: false,
        clearable: false,
        placeholder: "请选择标识分类"
      },
      form: {
        name: "",
        cat_id: ""
      },
      isShowDialog: false,
      formLabelWidth: "120px",
      total: 0,
      listData: []
    };
  },
  created() {
    this.getCategory();
  },
  mounted() {
    this.getList();
  },
  watch: {
    isShowDialog(newValue, oldValue) {
      if (!this.isShowDialog) {
        this.clearable();
      }
    }
  },
  methods: {
    selectMenuInfo(row) {
      this.selectMenu = row.cat_name;
      this.cat_parentid = row.cat_id;
      this.isShowPopover = false;
      this.getList();
    },
    async getCategory() {
      let { data } = await identifierApi.list();
      let topCateory = {
        cat_name: "顶级分类",
        cat_id: "",
        cat_type: 0
      };
      data.unshift(topCateory);
      this.treeParams.menuList = data.filter(
        item => item.cat_type == 0
      );
      console.log(this.treeParams.menuList);
    },
    async getList() {
      let { data } = await identifierApi.list(this.pageInfo);
      this.listData = data;
    },
    handleOpen() {
      this.isShowDialog = true;
      this.isEdit = false;
    },
    clearable() {
      this.isShowDialog = false;
      this.form.name = "";
      this.cat_parentid = "";
      this.selectMenu = "";
      this.form.cat_id = "";
      this.$refs["form"].resetFields();
    },
    async addCateory() {
      if (!this.form.name) {
        this.$message({
          type: "error",
          message: "请完整的填写分类名称"
        });
        return false;
      }
      let { code, message } = await identifierApi.updateCategory({
        cat_id: this.form.cat_id,
        cat_name: this.form.name,
        cat_parent_id: this.cat_parentid
      });
      if (code == 200) {
        this.clearable();
        this.getList();
        this.getCategory();
        this.$message({
          type: "success",
          message: "添加成功!"
        });
      } else {
        this.$message({
          type: "error",
          message
        });
      }
    },
    handleEdit(row) {
      this.isShowDialog = true;
      this.form.name = row.cat_name;
      this.selectMenu = row.cat_parent_name;
      this.form.cat_id = row.cat_id;
      this.cat_parentid = row.cat_parent_id;
      this.isEdit = true;
      console.log(row);
    },
    handleDelete(id) {
      let ids = [];
      ids.push(id);
      this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          let { code, message } = await identifierApi.delete({ ids });
          if (code == 200) {
            this.$message.success("删除成功!");
            this.getList();
          }
        });
    }
  }
};
</script>
<style lang="less" scoped>
.filter-box {
  padding: 24px 20px 6px;
  background: var(--table-header-background);
  /deep/.el-table__body {
    font-size: 14px !important;
  }
  .el-button + .el-button {
    padding: 0;
  }
}
/deep/.el-table__cell .cell {
  padding-left: 16px !important;
}
.table-state {
  .iconfont {
    cursor: pointer;
    margin-left: 8px;
    font-size: 16px;
    color: #7b7e8c;
  }
}
.el-form {
  /deep/ .el-form-item {
    .el-form-item__label {
      padding-right: 0;
    }
  }
  /deep/.el-select .el-input {
    width: auto !important;
  }
  /deep/ .el-button.el-button--primary {
    min-width: 80px;
  }
}
/deep/.el-tree-node__content {
  height: 34px !important;
  line-height: 34px;
}
/deep/ .el-dialog {
  .el-dialog__header {
    border-radius: 4px;
  }
  .el-dialog__body {
    padding: 14px 24px;
  }
  .el-dialog__footer {
    padding-right: 24px;
    border-radius: 4px;
    padding-bottom: 24px;
    .el-button {
      width: 60px;
      height: 32px;
      padding: 8px 0;
      text-align: center;
    }
  }
}
/deep/.el-input--mini .el-input__inner {
  height: 32px;
}
/deep/.el-pagination__editor.el-input .el-input__inner {
  height: 30px;
}
</style>
